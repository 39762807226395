define(['jquery'], function($) {
  
  return function(result) {

    $('[data-component=\'updateBulkBuy\'] span').remove();

    var selector = $('[data-component="updateBulkBuy"]');
    var first_str = '<span class=\'discount-block-1\'>%d for <strong>%s</strong></span>';
    var middle_str = '<span class=\'discount-block-2\'>Up to %d for <strong>%s</strong> each</span>';
    var last_str = '<span class=\'discount-block-3\'>Up to %d or more for <strong>%s</strong> each</span>';
    return $.map(result, function(val, i) {

      if (i === 0) {
        $(selector).append(first_str.replace('%s', val.price).replace('%d', val.products));
      } else if (i < result.length - 1) {
        $(selector).append(middle_str.replace('%s', val.price).replace('%d', val.products));
      } else {
        $(selector).append(last_str.replace('%s', val.price).replace('%d', val.products));
      }

    });
  };
});
